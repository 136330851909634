import { useMsal } from "@azure/msal-react";
import { ExitToApp } from "@mui/icons-material";
import { Button, useMediaQuery } from "@mui/material";
import { useAppDispatch } from "../../app/hooks";
import { theme } from "../../constants/theme";
import { logout } from "./authorizationSlice";

const MSLogoutButton = () => {
  const { instance } = useMsal();
  const dispatch = useAppDispatch()
  const handleLogout = (logoutType: string) => {
    dispatch(logout(null))
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Button
      endIcon={<ExitToApp sx={{ fontSize: "2em", margin: "auto" }} />}
      onClick={() => handleLogout("redirect")}
      color="inherit"
      sx={{ fontSize: "1em" }}
    >
      {matches ? "Logout" : ""}
    </Button>
  );
};

export default MSLogoutButton;
