import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store, history } from "./app/store";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./msAuthConfig";
import { GoogleOAuthProvider } from '@react-oauth/google';

import App from "./App";

import reportWebVitals from "./reportWebVitals";
import "./index.css";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./constants/theme";
import { OAUTH_GOOGLE_CLIENT_ID } from "./constants/oauthClientId";

const container = document.getElementById("root")!;
const root = createRoot(container);
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
   <React.StrictMode>
    <GoogleOAuthProvider clientId={OAUTH_GOOGLE_CLIENT_ID}>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <Router history={history}>
              <App />
            </Router>
          </Provider>
        </ThemeProvider>
      </MsalProvider>
    </GoogleOAuthProvider>
   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
