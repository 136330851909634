import * as React from "react";
import Link, { LinkProps } from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Typography, useTheme } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { useAppSelector } from "../app/hooks";
import {
  selectPagesCatDict,
  selectPages,
  selectIsLoadingUI,
} from "../features/UILayout/UILayoutSlice";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

const NavBreadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const theme = useTheme();
  
  
  const isLoadingUI = useAppSelector(selectIsLoadingUI)
  const pages = useAppSelector(selectPages);
  const pagesCatDict = useAppSelector(selectPagesCatDict);

  const mapLinkToName = React.useCallback((pathnames: string[]) => {
    if (!pages || isLoadingUI) return ['', '']
    const pageInfo = pages.find((val) => val.link === pathnames[0]);
    if (!pageInfo || !pagesCatDict[pageInfo.link]) return ['','']
    const categoryInfo = pagesCatDict[pageInfo.link].find((val) => val.category === pathnames[1]);
    if (!categoryInfo) return ['','']
    
    return [pageInfo.title, categoryInfo.title];
  }, [pages, pagesCatDict, isLoadingUI])

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNext fontSize="small" />}
      sx={{ mb: theme.spacing(4), lineHeight: '40px' }}
    >
      {mapLinkToName(pathnames).map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        return last ? (
          <Typography color="text.primary" key={to}>
            {value}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit" to={to} key={to}>
            {value}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};

export default NavBreadcrumbs;
