import { Box, useTheme } from "@mui/material";
import { NewsInfo } from "../features/news/newsSlice";
import ExpandCard from "./ExpandCard";
import NoResultsCard from "./NoResultsCard";

interface NewsDisplayProps {
  newsList: NewsInfo[];
}

function NewsDisplay(props: NewsDisplayProps) {
  const theme = useTheme();
  const { newsList } = props;
  return (
    <Box
      marginTop={theme.spacing(4)}
      minHeight="200px"
      display="flex"
      flexDirection="column"
    >
      {newsList.length === 0 && (
        <NoResultsCard
          title="Không có thông tin ở mục này"
          msg=""/>
      )}
      {newsList.map((news, index) => (
        <ExpandCard key={index} header={news.header} content={news.content} />
      ))}
    </Box>
  );
}

export default NewsDisplay;
