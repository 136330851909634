import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Divider } from '@mui/material';
import '../styles/table.css'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface CardProps {
  header: string,
  content: string,
}

export default function ExpandCard(props: CardProps) {
  const [expanded, setExpanded] = React.useState(false);
  const { header, content } = props

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ width: '100%', marginBottom: '10px' }}>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>  
        <CardHeader
          title={<Typography sx={{fontWeight: 'bold', fontSize: {'sm': '1.5em', 'xs': '1.2em'}}}>{header}</Typography>}
          sx={{flexGrow: 1}}
          onClick={handleExpandClick}
          // subheader="September 14, 2016"
        />
        {/* <CardMedia
          component="img"
          height="194"
          image="/static/images/cards/paella.jpg"
          alt="Paella dish"
        /> */}
        <CardActions disableSpacing>
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            aria-label="show more"
            onClick={handleExpandClick}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider/>
        <CardContent sx={{fontSize: '1em', lineHeight: '40px'}}>
          <div dangerouslySetInnerHTML={{__html: content}}></div>
        </CardContent>
      </Collapse>
    </Card>
  );
}
