import { Button, styled } from "@mui/material";

const StyledLoginButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "4em",
  fontSize: "1em",
  textTransform: "none",
  backgroundColor: "white",
  paddingLeft: "2em",
  paddingRight: "2em",
  borderRadius: "4em",
  fontWeight: "bold",
  boxShadow: "2px 2px 10px grey",
  marginTop: 5,
  marginBottom: 20,
  color: theme.palette.secondary.main,
  "&:hover": {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.light,
  },
})) as typeof Button;

export default StyledLoginButton;
