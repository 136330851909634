import { Paper, Typography } from "@mui/material";

interface NoResultsCardProps {
  title: string;
  msg: string;
}

const NoResultsCard = (props: NoResultsCardProps) => {
  return (
    <Paper
      elevation={3}
      sx={{
        height: "100%",
        paddingTop: "100px",
        paddingBottom: "100px",
        textAlign: "center",
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: "10px",
      }}
    >
      <img
        src="/UIIconPack/no-results.png"
        alt='no results'
        width={100}
        style={{ marginBottom: 20 }}
      />
      <Typography variant="h5" sx={{ textAlign: "center", mb: 2 }}>
        {props.title}
      </Typography>
      <Typography variant="body1" sx={{ textAlign: "center" }}>
        {props.msg}
      </Typography>
    </Paper>
  );
};

export default NoResultsCard;
