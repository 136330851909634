import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import { createReduxHistoryContext } from "redux-first-history";

import counterReducer from '../features/counter/counterSlice';
import authorizationReducer from '../features/authorization/authorizationSlice';
import newsReducer from '../features/news/newsSlice'
import UILayoutReducer from '../features/UILayout/UILayoutSlice'
import lookupReducer from '../features/lookup/lookupSlice'
import { createBrowserHistory } from 'history';

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });



export const store = configureStore({
  reducer: {
    counter: counterReducer,
    authorization: authorizationReducer,
    router: routerReducer,
    news: newsReducer,
    uiLayout: UILayoutReducer,
    lookup: lookupReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([routerMiddleware]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const history = createReduxHistory(store);