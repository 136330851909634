import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { API } from "../../constants/api";
import { timeout, toLowerKeys } from "../../utils/common";

export interface NewsInfo {
  header: string;
  content: string;
}

interface NewsState {
  isLoading: boolean;
  newsList: NewsInfo[];
}

const initialState: NewsState = {
  isLoading: false,
  newsList: [],
};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNewsList: (state, action: PayloadAction<NewsInfo[]>) => {
      state.newsList = action.payload
    }
  },
  extraReducers: (builder) => {
    // Fetch news cases
    builder
      .addCase(fetchNews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNews.fulfilled, (state, action) => {
        state.newsList = action.payload;
        state.isLoading = false;
      });
    // Fetch news view as cases
    builder
      .addCase(fetchNewsViewAs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNewsViewAs.fulfilled, (state, action) => {
        state.newsList = action.payload;
        state.isLoading = false;
      });
  },
});

export const fetchNews = createAsyncThunk(
  "news/fetchNews",
  async (category: string) => {
    await timeout(1000);
    const response = await API.get("/news", { params: { category } });
    const newsList = response.data.map((val: any) => toLowerKeys(val));
    return newsList;
  }
);

export const fetchNewsViewAs = createAsyncThunk(
  "news/fetchNewsViewAs",
  async ({ category, userInfo }: { category: string; userInfo: string }) => {
    await timeout(1000);
    const response = await API.get("viewas", {
      params: { category, id: userInfo },
    });
    const newsList = response.data.map((val: any) => toLowerKeys(val));
    return newsList;
  }
);

export const selectIsLoadingNews = (state: RootState) => state.news.isLoading;
export const selectNewsList = (state: RootState) => state.news.newsList;
export const { setNewsList } = newsSlice.actions

export default newsSlice.reducer;
