import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import NavBreadcrumbs from "../../components/NavBreadcrumbs";
import NoResultsCard from "../../components/NoResultsCard";
import {
  fetchLookup,
  selectMatchedLookupInfos,
} from "../../features/lookup/lookupSlice";

function Lookup() {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [query, setQuery] = useState("");
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  useEffect(() => {
    dispatch(fetchLookup(""));
  }, [dispatch]);

  const matchedLookupInfos = useAppSelector(selectMatchedLookupInfos);

  const handleChangeQuery = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    if (timer) {
      clearTimeout(timer)
    }
    const newTimer = setTimeout(() => {
      handleSearch(e.target.value)
    }, 300)
    setTimer(newTimer)
  };

  const handleSearch = (query: string) => {
    dispatch(fetchLookup(query));
  };

  return (
    <Box>
      <NavBreadcrumbs />

      <TextField
        label="Tìm kiếm"
        sx={{ width: "100%" }}
        value={query}
        onChange={handleChangeQuery}
      />
      {matchedLookupInfos.length === 0 && (
        <NoResultsCard
          title="Không tìm kiếm được kết quả"
          msg=""
        />
      )}
      {matchedLookupInfos.length !== 0 && (
        <Paper
          sx={{
            mt: theme.spacing(2),
          }}
        >
          <List>
            {matchedLookupInfos.map((info) => (
              <>
                <ListItem>
                  <ListItemText
                    primary={`${info.id} - ${info.name}`}
                    secondary={`${info.emails[0]}`}
                  />
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </Paper>
      )}
    </Box>
  );
}

export default Lookup;
