import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { CategoryInfo, PageInfo } from "../../constants/interfaces/page";

interface PagesCatDict {
  [key: string]: CategoryInfo[]
}

interface UILayoutState {
  pages: PageInfo[];
  pagesCatDict: PagesCatDict
  isLoading: boolean;
}

const initialState: UILayoutState = {
  pages: [],
  pagesCatDict: {},
  isLoading: true,
};

const UILayoutSlice = createSlice({
  name: "ui-layout",
  initialState,
  reducers: {
    setPages: (state, action: PayloadAction<PageInfo[]>) => {
      state.pages = action.payload
      state.pagesCatDict = {}
    },
    setCategories: (state, action: PayloadAction<{key: string, value: CategoryInfo[]}>) => {
      state.pagesCatDict[action.payload.key] = action.payload.value
    },
    setIsLoadingUI: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    }
  }
});

export const { setPages, setCategories, setIsLoadingUI } = UILayoutSlice.actions;
export const selectPages = (state: RootState) => state.uiLayout.pages
export const selectPagesCatDict = (state: RootState) => state.uiLayout.pagesCatDict
export const selectIsLoadingUI = (state: RootState) => state.uiLayout.isLoading

export default UILayoutSlice.reducer;
