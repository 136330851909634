import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchNews, selectNewsList } from "../../features/news/newsSlice";

import NavBreadcrumbs from "../../components/NavBreadcrumbs";
import { Box } from "@mui/system";
import NewsDisplay from "../../components/NewsDisplay";

const NewsReader = () => {
  const newsList = useAppSelector(selectNewsList);
  const dispatch = useAppDispatch();
  const { category } = useParams();

  useEffect(() => {
    if (category) dispatch(fetchNews(category));
  }, [category, dispatch]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ height: "100%", minHeight: "60vh" }}
    >
      <NavBreadcrumbs />
      <NewsDisplay newsList={newsList}/>
    </Box>
  );
};

export default NewsReader;
