import { useEffect } from "react";
import { Box } from "@mui/material";

import NavBreadcrumbs from "../../components/NavBreadcrumbs";
import NewsDisplay from "../../components/NewsDisplay";
import ViewAsForm from "./ViewAsForm";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectNewsList, setNewsList } from "../../features/news/newsSlice";

function ViewAsPage() {
  const newsList = useAppSelector(selectNewsList);
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setNewsList([]))
  }, [dispatch])
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ height: "100%", minHeight: "60vh" }}
    >
      <NavBreadcrumbs />
      <ViewAsForm />
      <NewsDisplay newsList={newsList} />
    </Box>
  );
}

export default ViewAsPage;
