import React, { useCallback, useEffect } from "react";
import StyledLoginButton from "../../components/StyledLoginButton";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getGGIdTokenByCode,
  login,
  selectAccessToken,
  selectAccountDomain,
  updateUserProfile,
  validateAccessToken,
} from "./authorizationSlice";


import { useGoogleLogin } from "@react-oauth/google";
import { Google } from "@mui/icons-material";
import { AccountDomain } from "../../constants/interfaces/accountDomain";
import jwtDecode from "jwt-decode";

/**
 * Renders a button which, when selected, will open a popup for login
 */

interface TokenInfo {
  name: string;
  picture: string;
  email: string;
}
export const GoogleLoginButtonV2 = () => {
  const dispatch = useAppDispatch();
  const accountDomain = useAppSelector(selectAccountDomain);
  const accessToken = useAppSelector(selectAccessToken);

  const handleLoginSuccess = useCallback(
    (accessToken: string) => {
      dispatch(
        login({
          accessToken: accessToken,
          accountDomain: AccountDomain.GOOGLE,
        })
      );
      try{
        const tokenInfo = jwtDecode(accessToken) as TokenInfo;
        dispatch(
          updateUserProfile({
            name: tokenInfo.name,
            email: tokenInfo.email,
            avaUrl: tokenInfo.picture,
          })
        );
      } catch (e) {
        console.log(e);
      }
      dispatch(validateAccessToken());
    },
    [dispatch,]
  );

  useEffect(() => {
    const accessToken = localStorage.getItem("gg-access-token") || "";
    handleLoginSuccess(accessToken);
  }, [handleLoginSuccess]);

  useEffect(() => {
    if (!accessToken) return;
    if (accountDomain === AccountDomain.GOOGLE)
      localStorage.setItem("gg-access-token", accessToken);
    try {
      const tokenInfo = jwtDecode(accessToken) as TokenInfo;
      dispatch(
        updateUserProfile({
          name: tokenInfo.name,
          email: tokenInfo.email,
          avaUrl: tokenInfo.picture,
        })
      );
    } catch (e) {
      console.log(e);
    }
    dispatch(validateAccessToken());
  }, [accessToken, accountDomain, dispatch]);


  const GGlogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      const raw_data = JSON.stringify(codeResponse);
      const newCodeResponse = JSON.parse(raw_data);
      dispatch(
        getGGIdTokenByCode(`${newCodeResponse.authuser}|${codeResponse.code}`)
      );
    },
    flow: "auth-code",
  });

  return (
    <StyledLoginButton startIcon={<Google />} onClick={() => GGlogin()}>
      Đăng nhập với Google
    </StyledLoginButton>
  );
};
