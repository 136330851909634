import React, { useEffect } from "react";
import Papa from "papaparse";

import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import "./App.css";
import {
  selectIsLoadingUI,
  selectPages,
  selectPagesCatDict,
  setCategories,
  setIsLoadingUI,
  setPages,
} from "./features/UILayout/UILayoutSlice";
import {
  selectAvailFeatures,
  selectIsLoggedIn,
} from "./features/authorization/authorizationSlice";

import { CategoryInfo, PageInfo } from "./constants/interfaces/page";
import { Pages, UnauthenticatePages } from "./constants/pages";

import Layout from "./pages/Layout";
import PageContent from "./pages/PageContent";
import NewsReader from "./pages/NewsReader";

import {
  getPageLayoutsURL,
  getPagesURL,
  getSpecialRoute,
} from "./utils/layout";
import { WebsitTitle } from "./constants/UIParameter";
import FormsEmbed from "./pages/FormsEmbed";

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  let location = useLocation();
  if (isLoggedIn) return children;
  else return <Navigate to="/login" state={{ from: location }} replace />;
};

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pages = useAppSelector(selectPages);
  const isLoadingUI = useAppSelector(selectIsLoadingUI);
  const pagesCatDict = useAppSelector(selectPagesCatDict);
  const availFeatures = useAppSelector(selectAvailFeatures);

  useEffect(() => {
    document.title = WebsitTitle
  }, []);

  useEffect(() => {
    Papa.parse(getPagesURL(), {
      download: true,
      header: true,
      complete(results) {
        dispatch(setPages(results.data as PageInfo[]));
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (pages.length !== 0) {
      pages.forEach((pageInfo) => {
        Papa.parse(getPageLayoutsURL(pageInfo.link), {
          download: true,
          header: true,
          complete(results) {
            dispatch(
              setCategories({
                key: pageInfo.link,
                value: results.data as CategoryInfo[],
              })
            );
          },
        });
      });
    }
  }, [dispatch, pages]);

  useEffect(() => {
    if (Object.keys(pagesCatDict).length === pages.length) {
      dispatch(setIsLoadingUI(false));
    }
  }, [pagesCatDict, pages, dispatch, navigate]);

  return (
    <>
      {!isLoadingUI && (
        <Routes>
          {Object.entries(Pages).map(([index, pageInfo]) => {
            if (
              UnauthenticatePages.some((value) => value.link === pageInfo.link)
            )
              return (
                <Route
                  path={pageInfo.link}
                  key={pageInfo.link}
                  element={<pageInfo.component />}
                />
              );
            return null;
          })}
          <Route element={<Layout />}>
            {Object.entries(Pages).map(([_, pageInfo]) => {
              if (
                !UnauthenticatePages.some( 
                  (value) => value.link === pageInfo.link
                )
              )
                return (
                  <Route
                    path={pageInfo.link}
                    key={pageInfo.link}
                    element={
                      <RequireAuth>
                        <pageInfo.component />
                      </RequireAuth>
                    }
                  />
                );
              return null;
            })}
            {pages.map((pageInfo,id) => {
              return (
                <>
                  <Route
                    path={`/${pageInfo.link}`}
                    key={pageInfo.link}
                    element={
                      <RequireAuth>
                        <PageContent />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path={`/${pageInfo.link}/:category`}
                    key={`/${pageInfo.link}/:category`}
                    element={
                      <RequireAuth>
                        <NewsReader />
                      </RequireAuth>
                    }
                  />
                </>
              );
            })}
            {availFeatures.map((val) => getSpecialRoute(val))}
            <Route
              path={`/research/research-profile-update`}
              key={`/research/research-profile-update`}
              element={              
                <RequireAuth>                  
                  <FormsEmbed url="https://docs.google.com/forms/d/e/1FAIpQLSeOsWEJ7glTFsEpQlSraTUSgIJcoWBbEa8Iu6W8RnnTGdGTBA/viewform"></FormsEmbed>
                </RequireAuth>
              }
            />
            <Route
              path={`/research/research-publishment-support`}
              key={`/research/research-publishment-support`}
              element={              
                <RequireAuth>                  
                  <FormsEmbed url="https://forms.gle/r3TedRGDxA9yT5TY9"></FormsEmbed>
                </RequireAuth>
              }
            />
            
          </Route>
        </Routes>
      )}
    </>
  );
}

export default App;
