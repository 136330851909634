import { Menu as MenuIcon } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  IconButton,
  MenuItem,
  styled,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../app/hooks";
import { AccountDomain } from "../../constants/interfaces/accountDomain";
import { drawerWidth, PageTitle } from "../../constants/UIParameter";
import {
  selectAccountDomain,
  selectUserProfile,
} from "../../features/authorization/authorizationSlice";
import { GoogleLogoutButton } from "../../features/authorization/GoogleLogoutButton";
import MSLogoutButton from "../../features/authorization/MicrosoftLogoutButton";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  color: `white`,
  //background: `${theme.palette.primary.dark}`,
  background: `linear-gradient(32deg, rgba(30,144,255,1) 0%, rgba(5,5,150,1) 100%)`,
  zIndex: theme.zIndex.drawer - 1,
}));

const LeftToolBar = styled("div")({
  display: "flex",
  justifyContent: "flex-start",
  gap: 10,
  alignItems: "center",
  flexGrow: 1,
});

const Title = styled("h1")(({ theme }) => ({
  whiteSpace: "pre-line",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.2em",
    marginTop: 10,
  },
}));

interface AppBarProps {
  handleDrawerToggle: () => void;
}

export default function CustomAppBar(props: AppBarProps) {
  const { handleDrawerToggle } = props;
  const userProfile = useAppSelector(selectUserProfile);
  const accountDomain = useAppSelector(selectAccountDomain);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <StyledAppBar
      position="fixed"
      sx={{
        //width: { md: `calc(100% - ${drawerWidth}px)` },
        width: "100%",
        ml: { md: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <LeftToolBar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon sx={{ fontSize: "1em", margin: "auto" }} />
          </IconButton>
          <Title sx={{ display: { md: "none" } }}>{PageTitle}</Title>
        </LeftToolBar>

        <Typography
          variant="body1"
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          {userProfile && userProfile.email}
        </Typography>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar src={(userProfile && userProfile.avaUrl) || ""} />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>
            {accountDomain === AccountDomain.GOOGLE ? (
              <GoogleLogoutButton />
            ) : (
              <MSLogoutButton />
            )}
          </MenuItem>
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
}
