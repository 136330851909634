import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { isTeacherDomain } from "../../constants/UIParameter";
import { fetchNewsViewAs } from "../../features/news/newsSlice";
import {
  selectPages,
  selectPagesCatDict,
} from "../../features/UILayout/UILayoutSlice";

const invisible_option = ["finding"];

interface FormState {
  searchQuery: string;
  page: string;
  category: string;
}

function ViewAsForm() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const pages = useAppSelector(selectPages);
  const pagesCatDict = useAppSelector(selectPagesCatDict);

  const [values, setValues] = useState<FormState>({
    searchQuery: "",
    page: pages[0].link,
    category: pagesCatDict[pages[0].link][0].category,
  });
  const handleSubmit = () => {
    dispatch(
      fetchNewsViewAs({
        category: values.category,
        userInfo: values.searchQuery,
      })
    );
  };
  const handleChange =
    (prop: keyof FormState) =>
    (
      event:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent
    ) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  return (
    <Box>
      <Grid container spacing={theme.spacing(2)}>
        <Grid item xs={12} md={5}>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              label={isTeacherDomain ? "MSCB" : "MSSV"}
              sx={{ width: "100%" }}
              value={values.searchQuery}
              onChange={handleChange("searchQuery")}
              inputProps={{
                style: {backgroundColor: 'white'}
              }}
            />
            <FormHelperText sx={{ display: { xs: "none", md: "block" } }}>
              {isTeacherDomain ? "Nhập mã số cán bộ" : "Nhập mã số sinh viên"}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="page-selection">Trang</InputLabel>
            <Select
              labelId="page-selection"
              id="page-selection"
              sx={{ width: "100%", backgroundColor: 'white' }}
              label="Trang"
              value={values.page}
              onChange={handleChange("page")}
            >
              {pages.map((pageInfo) => {
                const ind = invisible_option.findIndex(
                  (val) => val === pageInfo.link
                );
                if (ind === -1)
                  return (
                    <MenuItem value={pageInfo.link}>{pageInfo.title}</MenuItem>
                  );
                return <></>
              })}
            </Select>
            <FormHelperText sx={{ display: { xs: "none", md: "block" } }}>
              Chọn trang cần xem thử
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="category-selection">Mục</InputLabel>
            <Select
              labelId="category-selection"
              id="category-selection"
              label="Mục"
              sx={{ width: "100%", backgroundColor: 'white' }}
              value={values.category}
              onChange={handleChange("category")}
            >
              {pagesCatDict[values.page].map((categoryInfo) => {
                return (
                  <MenuItem value={categoryInfo.category}>
                    {categoryInfo.title}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText sx={{ display: { xs: "none", md: "block" } }}>
              Chọn mục cần xem thử
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={1}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ height: 56, width: "100%" }}
          >
            View as
          </Button>
          <FormHelperText> </FormHelperText>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ViewAsForm;
