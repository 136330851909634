import React, { useEffect } from "react";
import { logout, selectAccessToken, setIsLoading, validateAccessToken } from "./authorizationSlice";

import {
  GoogleLogout,
} from "react-google-login";

import { gapi } from "gapi-script";
import { OAUTH_GOOGLE_CLIENT_ID } from "../../constants/oauthClientId";
import { Button, useMediaQuery } from "@mui/material";
import { theme } from "../../constants/theme";

import { ExitToApp } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingStatus } from "../../constants/interfaces/loadingStatus";

export function GoogleLogoutButton() {
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(selectAccessToken)

  useEffect(() => {
    if (accessToken) return
    dispatch(setIsLoading(LoadingStatus.LOADING))
    dispatch(validateAccessToken());
  }, [accessToken, dispatch]);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: OAUTH_GOOGLE_CLIENT_ID,
        scope: "email",
      });
    }

    gapi.load("client:auth2", start);
  }, []);

  const handleSuccessResponse = () => {
    dispatch(logout(null));
  };
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <GoogleLogout
      clientId={OAUTH_GOOGLE_CLIENT_ID}
      render={(renderProps) => (
        <Button
            endIcon={<ExitToApp sx={{ fontSize: "2em", margin: "auto" }} />}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            color="inherit"
            sx={{ fontSize: "1em" }}
          >
            {matches ? "Logout" : ""}
          </Button>
      )}
      onLogoutSuccess={handleSuccessResponse}
    />
  );
}
