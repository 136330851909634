export const drawerWidth = 330;

export const isTeacherDomain = process.env.REACT_APP_DOMAIN === "teacher";

// Login Message
export const loginMsgTeacher = 
"Quý Thầy Cô vui lòng đăng nhập với email chính thức của trường.  Trong trường hợp quý Thầy Cô quên mật khẩu email, vui lòng liên hệ Ban quản lý Mạng (netadmin@hcmus.edu.vn).";
export const loginMsgStudent =
`Vui lòng đăng nhập với email chính thức mà nhà trường đã cung cấp (@student.hcmus.edu.vn, @*.fitus.edu.vn)`;
export const loginMsg = isTeacherDomain ? loginMsgTeacher : loginMsgStudent

// Page Title
export const PageTitleTeacher = `VNUHCM-US 
   Support`;
export const PageTitleStudent = PageTitleTeacher.replace('Support', 'Student')
export const PageTitle = isTeacherDomain ? PageTitleTeacher : PageTitleStudent

// Website Title
export const WebsiteTitleTeacher = `Support HCMUS`;
export const WebsiteTitleStudent = WebsiteTitleTeacher.replace('Support', 'Student')
export const WebsitTitle = isTeacherDomain ? WebsiteTitleTeacher : WebsiteTitleStudent