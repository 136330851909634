import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import NavBreadcrumbs from "../../components/NavBreadcrumbs";
import NoResultsCard from "../../components/NoResultsCard";
import {
  fetchLookup,
  selectMatchedLookupInfos,
} from "../../features/lookup/lookupSlice";
import { useNavigate } from "react-router-dom";

function EmbedForm({ url }: { url: string }) {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [query, setQuery] = useState("");
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const navigate = useNavigate();
  // const initialized = useRef(false)

  useEffect(() => {
    // if (!initialized.current)
    //initialized.current = true
    // navigate('/research')
    window.open(url, '_blank')?.focus();
  }, [])

  return (
    <Box>
      <NavBreadcrumbs />
      <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
        Nếu cửa sổ không tự động mở, quý thầy cô vui lòng click vào <a href={url} target="_blank">đây.</a>
      </Typography>
    </Box>
  );
}

export default EmbedForm;
