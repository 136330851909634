import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API } from "../../constants/api";
import { RootState } from "../../app/store";
import { AccountDomain } from "../../constants/interfaces/accountDomain";
import { LoadingStatus } from "../../constants/interfaces/loadingStatus";
import { isTeacherDomain } from "../../constants/UIParameter";
import { timeout } from "../../utils/common";


interface AuthorizationState {
  loggedIn: boolean;
  accessToken: string;
  status: LoadingStatus;
  accountDomain?: AccountDomain;
  userProfile?: UserProfile;
  availFeatures: string[];
}

const initialState: AuthorizationState = {
  loggedIn: false,
  accessToken: "",
  status: LoadingStatus.LOADING,
  availFeatures: [],
  accountDomain: isTeacherDomain
    ? AccountDomain.GOOGLE
    : AccountDomain.MICROSOFT,
};

// const loggedInInitialState: AuthorizationState = {
//   loggedIn: true,
//   accessToken: "",
//   status: LoadingStatus.DONE,
//   availFeatures: [],
//   accountDomain: AccountDomain.MICROSOFT,
// };

export interface UserProfile {
  email: string;
  name: string;
  avaUrl?: string;
}

interface LoginInfo {
  accessToken: string;
  accountDomain: AccountDomain;
}

export const authorizationSlice = createSlice({
  name: "authorization",
  initialState: initialState,
  reducers: {
    login: (state, action: PayloadAction<LoginInfo>) => {
      API.defaults.headers.common["Authorization"] = action.payload.accessToken;
      state.accessToken = action.payload.accessToken;
      state.accountDomain = action.payload.accountDomain;
    },
    updateUserProfile: (state, action: PayloadAction<UserProfile>) => {
      state.userProfile = action.payload;
    },
    logout: (state, payload: PayloadAction<null>) => {
      state.accessToken = "";
      API.defaults.headers.common["Authorization"] = "";
    },
    setIsLoading: (state, action: PayloadAction<LoadingStatus>) => {
      state.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(validateAccessToken.pending, (state) => {
        state.status = LoadingStatus.LOADING;
      })
      .addCase(validateAccessToken.fulfilled, (state, action) => {
        state.loggedIn = action.payload.isLoggedIn;
        state.availFeatures = action.payload.features;
        state.status = LoadingStatus.DONE;
      });

    builder
      .addCase(getGGIdTokenByCode.pending, (state) => {
        state.status = LoadingStatus.LOADING;
      })
      .addCase(getGGIdTokenByCode.fulfilled, (state, action) => {
        state.status = LoadingStatus.DONE;
        if (!action.payload.accessToken) return;
        state.accessToken = action.payload.accessToken;
        API.defaults.headers.common["Authorization"] =
          action.payload.accessToken;
        state.accountDomain = AccountDomain.GOOGLE;
      });
  },
});

export const validateAccessToken = createAsyncThunk(
  "authorization/validateAccessToken",
  async () => {
    try {
      await timeout(1000);
      const response = await API.get("/features");
      let features = response.data as string[];
      features = features.map((val) => val.toLowerCase());
      return {
        isLoggedIn: response.status === 200,
        features: features,
      };
    } catch {
      return {
        isLoggedIn: false,
        features: [],
      };
    }
  }
);

export const getGGIdTokenByCode = createAsyncThunk<any, string>(
  "authorization/getGGIdToken",
  async (codeResponse) => {
    try {
      const response = await API.post("/auth", codeResponse, {
        headers: {
          "Content-Type": "text/plain",
        },
      });
      return {
        accessToken: response.data,
      };
    } catch {
      return {
        accessToken: "",
      };
    }
  }
);

export const { login, setIsLoading, logout, updateUserProfile } =
  authorizationSlice.actions;

export const selectIsLoggedIn = (state: RootState) =>
  state.authorization.loggedIn;
export const selectAccessToken = (state: RootState) =>
  state.authorization.accessToken;
export const selectStatus = (state: RootState) => state.authorization.status;
export const selectAccountDomain = (state: RootState) =>
  state.authorization.accountDomain || "";

export const selectUserProfile = (state: RootState) =>
  state.authorization.userProfile;
export const selectAvailFeatures = (state: RootState) =>
  state.authorization.availFeatures;

export default authorizationSlice.reducer;
