import {
  Card,
  CardActionArea,
  CardContent,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CategoryInfo } from "../constants/interfaces/page";
import { getIconURL } from "../utils/layout";
import zIndex from "@mui/material/styles/zIndex";

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginTop: 50,
  marginBottom: 20,
  height: 20,
  [theme.breakpoints.down("md")]: {
    fontSize: "0.8em",
    margin: 0,
    marginTop: 10,
  },
})) as typeof Typography;

interface CategoryCardType {
  categoryInfo: CategoryInfo;
  onClick?: CallableFunction;
}

const CustomCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "space-around",
  height: "100%",
  borderRadius: 10,
  [theme.breakpoints.down("md")]: {
    // textAlign: "center",
    // justifyContent: "center",
    // alignItems: "center",
  },
}));

const ImageIcon = styled("img")(({ theme }) => ({
  width: 80,
  [theme.breakpoints.down("md")]: {
    position: 'relative', top: 0, left: 0, zIndex: 0
  },
  [theme.breakpoints.down("sm")]: {
    width: 50,
  },
}));

function CategoryCard(props: CategoryCardType) {
  const { categoryInfo, onClick } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const _handleSelectCard = () => {
    if (onClick) onClick();
  };
  return (
    <CustomCard elevation={5}

    >
      <CardActionArea onClick={_handleSelectCard} sx={{ height: "100%" }}>
        <CardContent
          sx={{
            padding: { sm: theme.spacing(4), xs: theme.spacing(2) },
            height: "100%",
            boxSizing: "border-box",
            background: 'radial-gradient(ellipse at left top, rgba(212,243,255,1) 25%, rgba(142,228,255,1) 100%)',
          }}
        >

          <ImageIcon src={getIconURL(categoryInfo.category)} />


          <Title
            variant="h5"
            gutterBottom
            component={"div"}
            sx={{
              height: "fit-content", 
              zIndex: 2
            }}

          >
            <Typography
            sx={{
              fontSize: '1.5rem',
              [theme.breakpoints.down("md")]: {
                textAlign: "end"  
              },
              [theme.breakpoints.down("sm")]: {
                fontSize: '1rem',
              },
              alignSelf: "end",
            }}
            >
              {categoryInfo.title}</Typography>            
          </Title>
          {/* {matches && (
            <Typography
              variant="body2"
              component={"div"}
              // sx={{ fontSize: 13, color: "gray", height: "fit-content" }}
            
            >
              {categoryInfo.description || ""}
            </Typography>
          )}  */}
        </CardContent>
      </CardActionArea>
    </CustomCard>
  );
}

export default CategoryCard;
