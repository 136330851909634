import { unstable_createMuiStrictModeTheme } from "@mui/material";
import { isTeacherDomain } from "./UIParameter";

const defaultTheme = {
  typography: {
    fontFamily: [
      'Segoe UI',
      "'Segoe UI Web (West European)'",
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
}

const teacherTheme = unstable_createMuiStrictModeTheme({
  palette: {
    primary: {
      main: "#6EA5EA",
      light: "#9FC5F5",
      dark: "#114FB6",
      contrastText: "#fff"
    },
    secondary: {
      main: "#ef6c00",
      light: "#ff9d3f",
      dark: "#b53d00",
      contrastText: "#000"
    },
    text: {
      primary: '#04093E',
      secondary: '#C9CFDE'
    }
  },
  ...defaultTheme
});

const studentTheme = unstable_createMuiStrictModeTheme({
  palette: {
    primary: {
      main: "#DC0000",
      light: "#FF7D7D",
      dark: "#DC0000",
      contrastText: "#fff"
    },
    secondary: {
      main: "#219DFF",
      light: "#C4E5FF",
      dark: "#005598",
      contrastText: "#000"
    },
    text: {
      primary: '#04093E',
      secondary: '#C9CFDE'
    }
  },
  ...defaultTheme
});

export const theme = isTeacherDomain ? teacherTheme : studentTheme