import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import CategoryCard from "../../components/CategoryCard";
import NoResultsCard from "../../components/NoResultsCard";
import { CategoryInfo, PageInfo } from "../../constants/interfaces/page";
import { SpecialCategory } from "../../constants/pages";
import { selectAvailFeatures } from "../../features/authorization/authorizationSlice";
import {
  selectPagesCatDict,
  selectPages,
} from "../../features/UILayout/UILayoutSlice";
import { getPageInfoByPath } from "../../utils/layout";

const PageContent = () => {
  const [categories, setCategories] = useState<CategoryInfo[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>()

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const pages = useAppSelector(selectPages);
  const pagesCatDict = useAppSelector(selectPagesCatDict);
  const availFeatures = useAppSelector(selectAvailFeatures);

  useEffect(() => {
    setPageInfo(getPageInfoByPath(pathname, pages))
  }, [pages, pathname])

  useEffect(() => {
    if (!pageInfo || !pagesCatDict[pageInfo?.link]) return;
    setCategories(pagesCatDict[pageInfo?.link].filter((categoryInfo) => {
      const isSpecial =
        SpecialCategory.findIndex(
          (specialCat) => specialCat === categoryInfo.category
        ) !== -1;
      const havePermission =
        availFeatures.findIndex((feat) => feat === categoryInfo.category) !==
        -1;
      return !isSpecial || havePermission;
    }));
  }, [pageInfo, pagesCatDict, availFeatures]);

  const handleNavigate = (category: string) => {
    navigate(`${pathname}/${category}`);
  };
  return (
    <div>
      {pageInfo && (
        <>
          <Typography
            sx={{
              fontSize: { xs: 25, md: 40 },
              marginBottom: 5,
              textAlign: {
                xs: "center",
                md: "left",
              },
              fontWeight: 'bold',
            }}
          >
            {" "}
            {pageInfo?.title}
          </Typography>
          {categories.length === 0 && (
            <NoResultsCard
            title="Không có chức năng khả dụng"
            msg=""/>
          )}
          <Grid container spacing={4}>
            {categories.map((categoryInfo, index) => {
              return (
                <Grid item key={index} xs={6} lg={4}>
                  <CategoryCard
                    categoryInfo={categoryInfo}
                    onClick={() => handleNavigate(categoryInfo.category)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </div>
  );
};

export default PageContent;
