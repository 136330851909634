import HomePage from "../pages/Home";
import LoginPage from "../pages/Login";


export type PageInfoType = {
  link: string;
  component: () => JSX.Element;
  title?: string;
  icon?: any;
  children?: PageInfoType[]
};

interface PageListType {
  [key: string]: PageInfoType
}

export const Pages:PageListType = {
  Home: {
    link: "/",
    component: HomePage,
  },
  Login: {
    link: "/login",
    component: LoginPage,
  },
};

export const UnauthenticatePages = [Pages.Login];

export const SpecialCategory = ['viewas', 'lookup']
