import {
  Paper,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { LoadingStatus } from "../../constants/interfaces/loadingStatus";
import {
  isTeacherDomain,
  loginMsg,
  PageTitle,
} from "../../constants/UIParameter";
import {
  selectIsLoggedIn,
  selectStatus,
} from "../../features/authorization/authorizationSlice";
import { GoogleLoginButtonV2 } from "../../features/authorization/GoogleLoginButtonV2";
import { MSLoginButton } from "../../features/authorization/MicrosoftLoginButton";
import LoadingPage from "../Loading";

const FormWrapper = styled(Card)(({ theme }) => ({
  display: "flex",
  margin: "auto",
  marginTop: 100,
  padding: 50,
  width: 300,
  borderRadius: 10,
  flexDirection: "column",
  alignItems: "center",
  cursor: "disabled",
  [theme.breakpoints.down("sm")]: {
    boxShadow: 0,
    borderRadius: 0,
    margin: 0,
    padding: 10,
    paddingTop: "10em",
    height: "100%",
    background: `linear-gradient(40deg, #2193b0, #6dd5ed)`,
    width: "100%",
    boxSizing: "border-box",
  },
})) as typeof Card;

const Background = styled("div")(({ theme }) => ({
  position: "absolute",
  background: `linear-gradient(40deg, #2193b0, #6dd5ed)`,
  top: 0,
  left: 0,
  right: 0,
  down: 0,
  width: "100vw",
  height: "100vh",
  minHeight: 800,
  [theme.breakpoints.down("sm")]: {
    minHeight: 0,
  },
}));

const Title = styled("h1")(({ theme }) => ({
  fontWeight: "bold",
  whiteSpace: "pre-line",
  textAlign: "center",
  color: theme.palette.primary.dark,
  textShadow: "#FFF 1px 0 20px",
  [theme.breakpoints.down("md")]: {
    color: "white",
    textShadow: "#FFF 0px 0 0px",
  },
}));

const Logo = styled("img")({
  margin: "auto",
  width: 200,
});

const modalBoxStyle = {
  outline: "none",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface LocationState {
  from: string | undefined;
}

const Login = () => {
  const [open, setOpen] = useState(true);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const navigate = useNavigate();
  const state = useLocation().state as LocationState;
  const from = state?.from || "/dashboard";
  const loadingStatus = useAppSelector(selectStatus);

  useEffect(() => {
    if (isLoggedIn) {
      return navigate(from);
    }
  }, [isLoggedIn, navigate, from]);

  return (
    <Background>
      {loadingStatus === LoadingStatus.LOADING && <LoadingPage />}
      <FormWrapper elevation={10}>
        <CardMedia>
          <Logo src="https://cdn.haitrieu.com/wp-content/uploads/2021/12/logo-hcmus-new.png" />
        </CardMedia>
        <CardContent>
          <Title>{PageTitle}</Title>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!isTeacherDomain && <MSLoginButton />}
          <GoogleLoginButtonV2/>
        </CardActions>
        <Typography
          sx={{ mt: 3, textAlign: "center" }}
          onClick={handleOpenModal}
        >
          {"Hướng dẫn đăng nhập"}
        </Typography>
      </FormWrapper>
      <Modal open={open} onClose={handleCloseModal}>
        <Paper sx={{ ...modalBoxStyle, width: 300 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {"Hướng dẫn đăng nhập"}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {loginMsg}
          </Typography>
        </Paper>
      </Modal>
    </Background>
  );
};

export default Login;
