import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { API } from "../../constants/api";
import { toLowerKeys } from "../../utils/common";

export interface LookupInfo {
  name: string,
  emails: string[],
  id: [],
}

interface LookupState {
  isLoading: boolean;
  matchedInfos: LookupInfo[];
}

const initialState: LookupState = {
  isLoading: false,
  matchedInfos: [],
};

export const lookupSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setMatchedInfos: (state, action: PayloadAction<LookupInfo[]>) => {
      state.matchedInfos = action.payload
    }
  },
  extraReducers: (builder) => {
    // Fetch news cases
    builder
      .addCase(fetchLookup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLookup.fulfilled, (state, action) => {
        state.matchedInfos = action.payload;
        state.isLoading = false;
      });
  },
});

export const fetchLookup = createAsyncThunk(
  "lookup",
  async (query: string) => {
    try {
      const response = await API.get("/lookup", { params: { query } });
      const matchedInfos = response.data.map((val: any) => toLowerKeys(val));
      return matchedInfos as LookupInfo[];
    } catch {
      return []
    }
  }
);

export const selectIsLoadingLookup = (state: RootState) => state.lookup.isLoading;
export const selectMatchedLookupInfos = (state: RootState) => state.lookup.matchedInfos;
export const { setMatchedInfos } = lookupSlice.actions

export default lookupSlice.reducer;
