import { Box, LinearProgress, styled } from "@mui/material";

const Logo = styled("img")(({ theme }) => ({
  width: 200,
}));

const LoadingPage = () => {
  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          position: "absolute",
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          zIndex: 5000,
        }}
      >
        <Box
          sx={{
            height: "200px",
          }}
        >
          <Logo src={process.env.PUBLIC_URL + "/Logo_HCMUS.png"} alt="logo" />
          <LinearProgress sx={{ width: 200 }} />
        </Box>
      </Box>
    </>
  );
};

export default LoadingPage;
