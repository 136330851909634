import { RequireAuth } from "../App";
import { PageInfo } from "../constants/interfaces/page";
import { Route } from "react-router-dom";
import ViewAsPage from "../pages/ViewAs";
import LookupPage from "../pages/Lookup";
import { isTeacherDomain } from "../constants/UIParameter";

export function getPageInfoByPath(pathname: string, pages: PageInfo[]) {
  const pageName = pathname.split("/")[1];
  const page = pages.find((val) => val.link === pageName);
  return page;
}

export function getPageLayoutsURL(
  pageName: string,
) {
  const url =
    isTeacherDomain
      ? `${process.env.PUBLIC_URL}/UILayout/${pageName}.csv` || ""
      : `${process.env.PUBLIC_URL}/StudentUILayout/${pageName}.csv` || "";
  return url;
}

export function getPagesURL() {
  return isTeacherDomain
    ? process.env.PUBLIC_URL + "/UILayout/portal-pages.csv"
    : process.env.PUBLIC_URL + "/StudentUILayout/portal-pages.csv";
}

export function getIconURL(category: string) {
  return isTeacherDomain
    ? `${process.env.PUBLIC_URL}/IconPack/${category}.png`
    : `${process.env.PUBLIC_URL}/StudentIconPack/${category}.png`;
}

export function getSpecialRoute(featureName: string) {
  if (featureName === "viewas") {
    return (
      <Route
        key={featureName}
        path={`/finding/viewas`}
        element={
          <RequireAuth>
            <ViewAsPage />
          </RequireAuth>
        }
      />
    );
  }
  if (featureName === "lookup") {
    return (
      <Route
        key={featureName}
        path={`/finding/lookup`}
        element={
          <RequireAuth>
            <LookupPage />
          </RequireAuth>
        }
      />
    );
  }
  return <></>;
}
