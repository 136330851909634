import {
  Box,
  Drawer,
  Icon,
  List,
  ListItemButton,
  ListItemText,
  styled,
  Toolbar,
} from "@mui/material";

import React, { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import CustomAppBar from "./CustomAppBar";
import { drawerWidth, PageTitle } from "../../constants/UIParameter";
import { useAppSelector } from "../../app/hooks";
import { selectStatus } from "../../features/authorization/authorizationSlice";
import { LoadingStatus } from "../../constants/interfaces/loadingStatus";
import LoadingPage from "../Loading";
import {
  selectIsLoadingUI,
  selectPages,
} from "../../features/UILayout/UILayoutSlice";
import { PageInfo } from "../../constants/interfaces/page";
import { getIconURL, getPageInfoByPath } from "../../utils/layout";
import { selectIsLoadingNews } from "../../features/news/newsSlice";
import { graphConfig } from "../../msAuthConfig";

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  width: drawerWidth,
  flexShrink: 0,
  padding: 0,
  [theme.breakpoints.down("md")]: {
    width: `${drawerWidth - 60}px`,
  },
}));

const PageWrapper = styled("div")({
  display: "flex",
});

const DrawerContainer = styled("div")({
  overflow: "auto",
});

const ContentWrapper = styled("main")(({ theme }) => ({
  flexGrow: 1,
  boxSizing: "border-box",
  padding: `${theme.spacing(4)} ${theme.spacing(14)}`,
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
  },
}));

const CustomListItem = styled(ListItemButton)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  height: 50,
  fontWeight: 400,
  color: "black",
  boxSizing: "border-box",
  backgroundColor: "transparent",
  "&.Mui-selected": {
    backgroundColor: "#ff96557f",
    color: `${theme.palette.primary.dark}`,
    fontWeight: 500,
    borderLeft: `5px solid ${theme.palette.primary.dark}`,
  },
})) as typeof ListItemButton;

const CustomToolBar = styled(Toolbar)(({ theme }) => ({
  height: 100,
  [theme.breakpoints.down("md")]: {
    height: 100,
  },
}));

const LogoComponent = styled("img")(({ theme }) => ({
  marginTop: theme.spacing(4),
  height: "5em",
}));

// const Title = styled("h1")(({ theme }) => ({
//   textAlign: "center",
//   whiteSpace: "pre-line",
//   [theme.breakpoints.down("md")]: {
//     fontSize: "1.2em",
//     marginTop: 10,
//   },
// }));

const Title = styled("h1")(({ theme }) => ({
  fontWeight: "bold",
  whiteSpace: "pre-line",
  textAlign: "center",
  color: theme.palette.primary.dark,
  textShadow: "#FFF 1px 0 20px",
  [theme.breakpoints.down("md")]: {
    textShadow: "#FFF 0px 0 0px",
  },
}));

const ImageIcon = styled("img")(({ theme }) => ({
  width: 80,
  [theme.breakpoints.down("sm")]: {
    width: 50,
  },
}));

const Layout = () => {
  const { pathname } = useLocation();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState<PageInfo>();

  const loadingStatus = useAppSelector(selectStatus);
  const pages = useAppSelector(selectPages);
  const isLoadingUI = useAppSelector(selectIsLoadingUI);
  const isLoadingNews = useAppSelector(selectIsLoadingNews)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    setCurrentPage(getPageInfoByPath(pathname, pages));
  }, [pathname, pages]);

  const drawer = (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        {/* <LogoComponent
          src={process.env.PUBLIC_URL + "/Logo_HCMUS.png"}
          alt="Logo HCMUS"
          sx={{ display: { xs: "none", md: "block" } }}
        /> */}
        <Title sx={{
          display: { xs: "none", md: "block" },
        }}>
          {PageTitle}
          </Title>
      </Box>
      <DrawerContainer>
        <List>
          {pages.map((pageInfo) => {
            return (
              <CustomListItem
                selected={currentPage === pageInfo}
                key={pageInfo.link}
                component={Link}
                to={pageInfo.link}
              >
                <Icon sx={{ marginRight: "10px" }}>{pageInfo.icon}</Icon>
                <ListItemText
                  sx={{ fontSize: "1em" }}
                  disableTypography
                  primary={pageInfo.title}
                />
              </CustomListItem>
            );
          })}
        </List>
      </DrawerContainer>
    </div>
  );
  return (
    <>
      <div style={{
        position: 'fixed',
        zIndex: -1,
        // width: '100vw',
        // height: '100vh',        
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,

      }}>
        <img src={getIconURL('Logo_HCMUS')} style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          height: '75vh',
          width: '75vw',
          objectFit: 'contain',
          opacity: 0.1,

        }} />
      </div>
      <PageWrapper>

        {(loadingStatus === LoadingStatus.LOADING ||
          isLoadingUI ||
          isLoadingNews) && <LoadingPage />}

        <CustomAppBar handleDrawerToggle={handleDrawerToggle} />

        <CustomDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{
            elevation: 0,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            background: 'transparent',
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: { md: drawerWidth, xs: `${drawerWidth - 40}px` },
              padding: 0,
            },
          }}
        >
          {drawer}
        </CustomDrawer>

        <CustomDrawer
          variant="permanent"
          sx={{

            display: { xs: "none", md: "block" },

            position: {
              xs: "relative",
              md: "sticky",
            },

            background: 'transparent',

            zIndex: 2560,

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: { md: drawerWidth, xs: `${drawerWidth - 40}px` },
              margin: 3,
              height: 'auto',
              borderRadius: 2,
              flexGrow: 0,
              border: 'solid 1px #fc9655',
              background: 'radial-gradient(circle, rgba(255,224,196,1) 0%, rgba(255,189,130,1) 100%)',
              boxShadow: '0px 0px 24px 5px rgba(255,189,130,0.5)'
            },

          }}

          PaperProps={{
            elevation: 0
          }}

          open
        >
          {drawer}
        </CustomDrawer>

        <ContentWrapper>
          <CustomToolBar />
          <Outlet />
        </ContentWrapper>

      </PageWrapper>
    </>
  );
};

export default Layout;
